<template>
    <div class="index">
        <!-- 新闻详情 -->
        <div :class="isPc === 'true' ? 'main' : 'phoneStyle main'">
            <div class="left">
                <div class="contain">
                    <div class="title">
                        <img src="../../assets/images/index/gaugeLogo.png" alt="">

                    </div>

                    <div class="text">
                        <p class="p2">
                            {{ articleInfo.title }}</p>
                        <p class="time p3">— {{ articleInfo.created }}</p>
                        <!-- <p class="p3" v-show="articleInfo.summary">
                            {{ articleInfo.summary }}
                        </p>
                        <img v-show="articleInfo.thumbnail" :src="`${$store.state.requestApi}${articleInfo.thumbnail}`" alt=""> -->
                        <p class="p3" v-html="articleInfo.content">
                        </p>
                        <!-- <div class="another">
                            <p class="p3">成瘾行为的神经生物学特征</p>
                            <p class="p3">成瘾行为的神经生物学特征</p>
                            <p class="p3">成瘾行为的神经生物学特征</p>
                            <p class="p3">成瘾行为的神经生物学特征</p>
                        </div> -->
                    </div>
                </div>
                <div class="btn">
                    <span class="span1" @click="back">返回列表</span>
                </div>
            </div>
            <div class="right">
                <div class="hot">
                    <ul>
                        <li>推荐文章</li>
                        <li v-for="item in hotArticle" :key="item.id" @click="toDetail(item.id)">{{ item.title }}</li>
                        <!-- <li>热门新闻标题1热门新闻标题1</li>
                        <li>热门新闻标题1热门新闻标题1</li>
                        <li>热门新闻标题1热门新闻标题1</li>
                        <li>热门新闻标题1热门新闻标题1</li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { newsDetail, newsListAPI } from '@/api/news'
export default {
    data() {
        return {
            isPc: true,
            articleInfo: {},
            nowPath: 1,
            articleId: 1,
            hotArticle: [], // 热门文章
        }
    },
    methods: {
        // 返回新闻列表
        back() {
            this.$router.push({
                path: '/universal',
                query: {
                    active: this.nowPath
                }
            })
        },
        // 获取文章详情
        async articleDetail() {
            const res = await newsDetail({ id: this.articleId });
            console.log(res);
            if (res.state === 'ok' && res.detail) {
                this.articleInfo = res.detail;
                // console.log(this.articleInfo.content)
            }
        },
        // 其他文章详情
        toDetail(val) {
            this.articleId = val;
            this.articleDetail();
            // console.log(val)

            // this.$router.push({
            //     path: '/universal/detail',
            //     query: {
            //         id: val,
            //         active: this.nowPath
            //     }
            // })
        },
        // 获取文章列表
        async getList() {
            // let time = Date.now();
            let info = {
                categoryId: Number(this.$store.state.healthTypeId),
                // withHot: true
                pageNumber: 1,
                pageSize: 5,
                // orderBy:'with_hot desc'
            }

            if (this.nowPath === 2) {
                info.categoryId = Number(this.$store.state.personalityTypeId)
            } else if (this.nowPath === 3) {
                info.categoryId = Number(this.$store.state.relationshipTypeId)
            } else if (this.nowPath === 4) {
                info.categoryId = Number(this.$store.state.growTypeId)
            } else {
                info.categoryId = Number(this.$store.state.healthTypeId)
            }
            const res = await newsListAPI(info);
            console.log(res);
            if (res.state === 'ok' && res.page) {
                // console.log(res.page)
                // this.hotArticle = res.page.list
                // this.paging.total = res.page.totalRow
                let arr = res.page.list;
                this.hotArticle = [];
                if (arr.length > 0) {
                    arr.forEach(item => {
                        if (item.withRecommend === true) {
                            // console.log(item)
                            this.hotArticle.push(item);
                        }
                    })
                }
            }
        },
    },
    created() {
        this.isPc = sessionStorage.getItem('isPc')
        this.articleId = Number(this.$route.query.id);
        this.articleDetail();
        this.nowPath = Number(this.$route.query.active);
        this.getList();
        let info = {
            id: Number(this.$route.query.id),
            active: Number(this.$route.query.active)
        }
        sessionStorage.setItem('article', JSON.stringify(info));
        // 监听 visibility change 事件 


        //     let urlData = (this.$route.path).split('/')
        // let val = urlData[urlData.length-1]
        // console.log(val)
        // if((this.$route.path).indexOf('article/') !== -1) {
        //   this.$router.push({
        //     path: '/linkDetail',
        //     query: {
        //       detailId: val
        //     }
        //   })
        // }
    },
    // destroyed() {
    //     alert('销毁')
    // }

}
</script>

<style lang="scss" scoped>
.index {
    padding-top: 40px;

    ::v-deep .image.image_resized.image-style-block-align-left {
        width: 90% !important;

        img {
            width: 100%;
        }
    }

    .main {
        flex-wrap: wrap;
        justify-content: space-between;

        .left {
            width: 68%;


            .contain {
                border: 1px solid #DDDDDD;

                .title {
                    width: 100%;
                    height: 48px;
                    background: var(--listStyle-color);
                    padding: 8px 20px;
                    box-sizing: border-box;
                    text-align: right;
                    // display: flex;
                    // position: relative;

                    // text-align: center;
                    // p {
                    //     width: 100%;
                    //     text-align: center;
                    //     height: 100%;
                    //     line-height: 32px;
                    //     font-weight: 600;
                    //     color: var(--titleFont-color);


                    // }

                    img {
                        // position: absolute;
                        // margin-right: 30px;
                        height: 32px;
                    }
                }

                .text {
                    padding: 20px 30px;
                    min-height: 500px;

                    .p2 {
                        text-align: center;
                        // display: inline-block;
                        height: 100%;
                        line-height: 32px;
                        font-weight: 600;
                        color: var(--titleFont-color);
                        margin-bottom: 20px;

                        span {
                            width: 28px;
                            height: 17px;
                            background: #D8D8D8;
                            border-radius: 2px;
                            font-size: 10px;
                            font-weight: 500;
                            color: #4B4B4B;
                            line-height: 17px;
                            text-align: center;
                            display: inline-block;
                        }
                    }

                    .p3 {
                        font-weight: 500;
                        color: var(--memoFont-color);

                        ::v-deep img {
                            width: 100% !important;
                        }

                        ::v-deep span {
                            img {
                                width: 100% !important;
                            }
                        }
                    }

                    .time {
                        text-align: right;
                        margin-bottom: 10px;
                        color: var(--newsTitle-color);

                    }

                    img {
                        width: 100%;
                        margin-top: 15px;
                        margin-bottom: 20px;
                    }
                }

                .another {
                    .p3 {
                        font-weight: 600;
                        color: var(--headFont-color);
                        margin-top: 20px;
                        cursor: pointer;

                        &:hover {
                            text-decoration: underline;
                            color: var(--hover-Color);
                        }
                    }
                }
            }

            .btn {
                text-align: center;
                margin: 20px 0px;

                span {
                    line-height: 40px;
                    display: inline-block;
                    width: 124px;
                    height: 40px;
                    border: 1px solid var(--custom-color);
                    font-weight: 600;
                    color: var(--headFont-color);
                    cursor: pointer;
                }
            }

            // background: #000;
        }

        .right {
            width: 30%;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    font-size: 15px;
                    height: 48px;
                    line-height: 48px;
                    border: 1px solid #EEEEEE;
                    border-top: none;
                    padding: 0 20px;
                    color: var(--memoFont-color);
                    cursor: pointer;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden; //溢出内容隐藏
                    text-overflow: ellipsis; //文本溢出部分用省略号表示
                    display: -webkit-box; //特别显示模式
                    -webkit-line-clamp: 1; //行数
                    line-clamp: 1;
                    -webkit-box-orient: vertical;

                    &:first-child {
                        border: none;
                        cursor: auto;
                        background: #EEEEEE;
                        font-weight: 600;
                        color: var(--headFont-color);

                    }
                }
            }



        }
    }
}

@media screen and (max-width: 600px) {
    .index {

        .main {
            .left {
                width: 704px;
            }

            .right {
                width: 350px;
            }
        }
    }
}</style>